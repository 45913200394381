td div{
    font-size: 12px !important;
  }
  th {
    font-size: 14px !important;
  }
  p{
    margin: 0;
    padding: 0;
    font-size: 12px;
  }
.main-buttons{
  height: 9rem;
  width: 7rem;
}

input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button,
  input[type='number'] {
    -webkit-appearance: none;
    margin: 1;
    -moz-appearance: textfield !important;
  }
  
.form-multi-select-tag {
  border: 1px solid #b1b7c1b1;
  padding: .2rem;
  background: #bfc2c747;
  border-radius: 5px;
  margin-left: 5px;
  margin-bottom: 5px;
  margin-top: 5px;
}

.form-multi-select-search {
  min-width: 20rem;
  border: 1px solid #b1b7c1;
  border-radius: 5px;
  padding: .4rem;
  outline: none;
}

.form-multi-select-option {
  margin-right: .5rem;
  padding: 10px;
}

.form-multi-select-optgroup-label {
  font-weight: 600;
  background: #e4e4e465;
  padding: .5rem;
  
  text-transform: capitalize;
}
.offer-frame {
    width: 100%;
    height: 30rem;
    opacity: .5;
}

.offer-frame {
    background-color: rgba(0, 0, 0, 0.322);
    background-image: url(https://i.pinimg.com/736x/20/2d/6a/202d6aed6af25e2afec26baea4b4cff4.jpg);
    background-size: cover;
    background-position: center;
    background-blend-mode: multiply;

}

.offer-frame:hover {
    background-color: rgba(0, 0, 0, 0.81);
    background-image: url(https://i.pinimg.com/736x/20/2d/6a/202d6aed6af25e2afec26baea4b4cff4.jpg);
    background-size: cover;
    background-position: center;
    background-blend-mode: multiply;
    transition: 100ms ease-in ;
    cursor: pointer;
}

.offer-img{
    cursor: pointer;
}

.upload-icon {
    width: 3rem;
    height: 3rem;
}

.horizontal-scrollable > .horizontal-row {
    overflow-x: auto;
    white-space: nowrap;
}
  
.story-border{
    width: 5rem;
    height: 5rem;
}

.rotate{
  transition: all 0.4s ease;
  transform: rotate(180deg);
}

.reverse{
  transition: all 0.4s ease;
  transform: rotate(0deg);
}